import React from "react"

import Button from "@material-ui/core/Button"

//import styles from './ArticlesFilter.module.scss'
import { makeStyles } from "@material-ui/core/styles"
import constants from "../theme/constants"
import colors from "../theme/colors"
import typography from "../theme/typography"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "50px",
    textAlign: "center",
  },
  button: {
    border: `1px solid ${colors.grey[1]}`,
    borderRadius: "4px",
    cursor: "pointer",
    display: "inline-block",
    fontSize: "1.1em",
    fontWeight: "300",
    margin: "12px",
    lineHeight: 1,
    padding: "10px 20px",
    textTransform: "uppercase",
  },
  active: {
    background: colors.base.black,
    borderColor: colors.base.black,
    color: colors.base.white,
  },
}))

const ArticlesFilter = ({ clickFunction, options, active }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {options.map((option, idx) => (
        <div
          key={idx}
          className={`${classes.button} ${
            option === active ? classes.active : ""
          }`}
          onClick={option => clickFunction(option)}
        >
          {option}
        </div>
      ))}
    </div>
  )
}

export default ArticlesFilter
