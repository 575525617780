import React, { useState, useEffect, useContext } from "react"
import { graphql, Link } from "gatsby"
import * as R from "ramda"

import urlForContentType from "../data/url-for-content-type.json"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import Hero from "../components/page/Hero"
import ArticlesFilter from "../components/page/ArticlesFilter"

import SearchContext from "../contexts/SearchContext"

import ArticleLift from "../components/page/ArticleLift"

import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"
import SEO from "../components/SEO"

import constants from "../components/theme/constants"

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: "1.5em",
  },
  heading: {
    textTransform: "uppercase",
  },
  container: {
    marginTop: "4rem",
  },
  allArticles: {
    marginTop: "2em",
    marginBottom: "4em",
  },
  viewMoreButton: {
    textAlign: "center",
  },
}))

const TarinatPage = ({ data, pageContext, location }) => {

  const featuredArticles = R.pathOr(
    [],
    ["contentfulArticlesMainPage", "featuredArticles"],
    data
  )
  const featuredArticleIds = featuredArticles.map(article => article.id)
  const allContentfulArticles = R.path(
    ["allContentfulArticle", "edges"],
    data
  ).map(node => node.node)

  const allArticles = [
    ...featuredArticles,
    ...allContentfulArticles.filter(
      article => !featuredArticleIds.includes(article.id)
    ),
  ]

  // Get all types set for articles to useas filters
  const allArticleTypes = R.uniq(
    allArticles.reduce((acc, article) => {
      if (article.types) {
        return [
          ...acc,
          ...(article.types ? article.types.map(type => type.type) : []),
        ]
      } else {
        return acc
      }
    }, [])
  )

  const [articles, setArticles] = useState(allArticles)

  // Filters and sorting
  const articleTypeFilters = ["Kaikki", ...allArticleTypes]
  const [filterState, setFilterState] = useState("Kaikki")

  const availableSorting = ["Osuvimmat", "Uusimmat ensin", "Vanhimmat ensin"]
  const [sortState, setSortState] = useState("Osuvimmat")

  // This is the "View more" functionality
  // It is basedaround pages as that may be use
  const articlesPerPage = 9
  //const maxResultPages = Math.floor(articles.length/resultsPerPage)+Math.ceil(articles.length%resultsPerPage/resultsPerPage);
  const [shownArticles, setShownArticles] = useState(articlesPerPage)

  const handleFilterChange = event => {
    setFilterState(event.target.textContent)
    filterArticles(event.target.textContent)
    setShownArticles(articlesPerPage) // Reset
  }

  const filterArticles = filter => {
    if (filter === "Kaikki") {
      // Reset
      setArticles(allArticles)
    } else {
      // Filter based on type
      setArticles(
        allArticles.filter(
          article =>
            filter === "Kaikki" ||
            (article.types && article.types.some(e => e.type === filter))
        )
      )
    }
  }
  const handleViewMoreArticles = () => {
    if (shownArticles < articles.length) {
      setShownArticles(shownArticles + articlesPerPage)
    } else {
      setShownArticles(articles.length)
    }
  }

  const classes = useStyles()

  const searchContext = useContext(SearchContext)
  searchContext.setHeroLoaded(true);

  return (
    <Layout>
      <SEO title="Toimitilahaku | Tarinat" />
      <Hero
        headline={R.path(["contentfulArticlesMainPage", "heading"], data)}
        backgroundImageFluid={R.path(
          ["contentfulArticlesMainPage", "headingImage", "gatsbyImageData"],
          data
        )}
        compact
      ></Hero>
      <ArticlesFilter
        clickFunction={handleFilterChange}
        options={articleTypeFilters}
        active={filterState}
      />
      <Container className={classes.container}>
        {articles.length && filterState === "Kaikki" && (
          <Grid container>
            <Grid item xs={12}>
              <ArticleLift
                size="big"
                image={articles[0].headingImage}
                heading={
                  articles[0].heading ? articles[0].heading : articles[0].title
                }
                text={R.path(["leadText", "leadText"], articles[0])}
                urlPath={
                  urlForContentType[
                    R.pathOr(["sys", "contentType", "sys", "id"], articles[0])
                  ]
                }
                slug={articles[0].slug}
              />
            </Grid>
          </Grid>
        )}
        {articles.length && (
          <Grid container className={classes.allArticles} spacing={5}>
            {// Slice is used to load more pages of results as requested by user
            R.slice(
              0,
              shownArticles,
              /* Before a slice is taken, articles are filtered based on:
                   1 - If there is a large featured article (only under "Kakki") then make sure the article doesnt appear in the list
                   2 - If a filter has been selected, only show articles that have the type 
                */
              articles
                .filter(
                  article =>
                    filterState !== "Kaikki" || article.id !== articles[0].id
                )
                .map(article => {
                  return (
                    <Grid item xs={12} md={6} key={article.id}>
                      <ArticleLift
                        image={article.headingImage}
                        heading={
                          article.heading ? article.heading : article.title
                        }
                        text={R.path(["leadText", "leadText"], article)}
                        urlPath={
                          urlForContentType[
                            R.path(["sys", "contentType", "sys", "id"], article)
                          ]
                        }
                        slug={article.slug}
                      />
                    </Grid>
                  )
                })
            )}

            {articles.length > shownArticles && (
              // This condition has the potential to display an extra "view more" button when on the last page of results
              // The impact and probability are both minimal, so leaving the issue instead of complicating the logic further
              <Grid item xs={12} className={classes.viewMoreButton}>
                <Button
                  variant="outlined"
                  color="primary"
                  disableRipple={true}
                  disableFocusRipple={true}
                  onClick={handleViewMoreArticles}
                >
                  Katso lisää
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </Layout>
  )
}

export default TarinatPage

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query TarinatPageQuery {
    allContentfulArticle(filter: { slug: { ne: "_blank" } }) {
      edges {
        node {
          id
          slug
          title
          heading
          leadText {
            leadText
          }
          headingImage {
            gatsbyImageData(
              formats: [AUTO],
              layout: CONSTRAINED, 
              width: 550, 
              quality: 70,
            )
          }
          types {
            type
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }
    contentfulArticlesMainPage(activeVersion: { eq: "Kyllä" }) {
      heading
      headingImage {
        gatsbyImageData(
          formats: [AUTO],
          layout: CONSTRAINED, 
          width: 1200, 
          quality: 70,
        )
      }
      featuredArticles {
        id
        slug
        heading
        title
        leadText {
          leadText
        }
        headingImage {
          gatsbyImageData(
            formats: [AUTO],
            layout: CONSTRAINED, 
            width: 550, 
            quality: 70,
          )
        }
        types {
          type
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      sys {
        contentType {
          sys {
            id
          }
        }
      }
    }
  }
`
